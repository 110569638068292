import React from "react";
import { Container } from "react-bootstrap";
import "./Footer.css";

const Footer = (props) => {
    return (
        <div className="Footer-Content ">
        <Container fluid className="custom-minwidth">
            <div className="d-flex align-items-center py-4">
            <div className="w-50">
                <h5>Johnson & Johnson </h5>
            </div>
            <div className="w-50">
                <span>
                Janssen Pharmaceutica NV, a Johnson & Johnson company • Turnhoutseweg 30, B—2340 Beerse — Belgium. All
                 rights reserved. This site is managed in accordance with Belgian Law, and is 
                 published by Janssen Pharmaceutica NV, who is solely responsible for its contents.
                </span>
            </div>
            </div>
            </Container>
            </div>
    )
};

export default Footer;