import React, { useState } from "react";
import { Container } from "react-bootstrap";
import PictureData from "./Picture.json";
import VideoData from "./Video.json";
import Iframe from 'react-iframe';
import "./Media.css";

export default function Media() {

    const pictureCount = PictureData.length;
    const videoCount = VideoData.length;
    const [pictures, setPictures] = useState(true);
    const [videos, setVideos] = useState(false);
    const [currentPicture, SetCurrentPicture] = useState(PictureData[0]);
    const [currentPictureKey, SetCurrentPictureKey] = useState(0);
    const [currentVideo, SetCurrentVideo] = useState(VideoData[0]);
    const [currentVideoKey, SetCurrentVideoKey] = useState(0);
    const picturesHandler = () => {
        setPictures(true);
        setVideos(false);
    };
    const videosHandler = () => {
        setPictures(false);
        setVideos(true);
    };

    const handlePictureClick = (value) => {
        const selectedMediaPic = PictureData[value];
        SetCurrentPicture(selectedMediaPic);
        SetCurrentPictureKey(value);
    }

    const handleVideoClick = (value) => {
        const selectedMediaVideo = VideoData[value];
        SetCurrentVideo(selectedMediaVideo);
        SetCurrentVideoKey(value);
    }

    const listPictures = PictureData.map(mediaPic =>
        <div key={mediaPic.id} className="list_image">
            <img className={mediaPic.id === currentPictureKey ? 'media-images-active' : "media-images"} onClick={() => handlePictureClick(mediaPic.id)} src={require(`../../Assets/Images/Media/${mediaPic.image}`)} alt={mediaPic.name}></img>
        </div>
    );

    const listVideos = VideoData.map(video =>
        <div key={video.id} className="list_video pb-3">
            <img className={video.id === currentVideoKey ? 'media-video-active' : "media-video"} onClick={() => handleVideoClick(video.id)} src={require(`../../Assets/Images/Media/${video.video_img}`)} alt={video.name}></img>
        </div>
    );



    return (
        <div className="Media-Content">
            <Container fluid className="custom-minwidth">
                <section>
                    <div className="row pt-3">
                        <div className="Media-header col-2"><h4 className="title-text">Media</h4></div>
                        <div className="Media-button col-6 d-flex">
                            <button onClick={picturesHandler} type="button" className={pictures ? "active me-2" : "me-2"}>Pictures ({pictureCount})</button>
                            <button onClick={videosHandler} type="button" className={videos ? "active me-2" : "me-2"}>Videos ({videoCount})</button>
                        </div>
                        <div className="col-4"></div>
                    </div>
                    <div className="main-image ">
                        {pictures && (
                            <div className="custom-picture pb-4 d-flex">
                                <div className="show-image pt-4 pe-4">
                                    <img className="current-image pb-2" src={require(`../../Assets/Images/Media/${currentPicture.image}`)} alt={currentPicture.name}></img>
                                    <p>{currentPicture.name}</p>
                                </div>
                                <div className="list-image pt-4">
                                    {listPictures}
                                </div>
                            </div>
                        )}
                        {videos && (
                            <div className="custom-video pb-4 d-flex">
                                <div className="show-video pt-4 pe-4">
                                    <Iframe url={currentVideo.video}
                                        width="700px"
                                        height="400px"
                                        id=""
                                        className=""
                                        display="block"
                                        position="relative" />
                                    {/* <iframe src={currentVideo.video} width="700" height="400" title={currentVideo.name} ></iframe> */}
                                    <p>{currentVideo.name}</p>
                                </div>
                                <div className="list-video pt-4">
                                    {listVideos}
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </Container>
        </div>
    )
};
