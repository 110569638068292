import React, { useState } from "react";
import { Container } from "react-bootstrap";
import WinnerData from "./Winners.json";
import "./Winners.css";

export default function Winners() {
    const [winner, setWinner] = useState(WinnerData[0]);
    const [currentKey, setCurrentKey] = useState(0);
    const handleClick = (key, value) => {
        const selectedWinner = WinnerData[value];
        setCurrentKey(value);
        setWinner(selectedWinner);
    }


    const listItems = WinnerData.map(winner =>
        <li key={winner.id} className="mb-3" onClick={() => handleClick("id", winner.id)} >
            <div className="winner-nav-list d-flex align-items-center">
                <div className="nav-left">
                    <p>
                        {winner.name}<br />
                        <span>{winner.about}</span>
                    </p>
                </div>
                <div className="nav-right">
                    {(currentKey === winner.id) && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg>}
                </div>
            </div>
        </li>
    );

    return (
        <div className="Winner-Content">
            <Container fluid className="custom-minwidth">
                <section className="d-flex">
                    <div className="custom-nav pt-3">
                        <h4>Awardees of the <br/> Janssen Prize for <br/> Creativity in Organic Synthesis</h4>
                        <ul className="mt-4">{listItems}</ul>
                    </div>
                    <div className="customwinner-block pt-4 ps-4">
                        <div className="left-region ps-3 pe-3">
                            <h4 className="winning_year">{winner.year}</h4>
                            {winner.year === "" ? (<h4 className="title-text">{winner.fullname}</h4>) : (<h4>{winner.fullname}</h4>)}
                            {(winner.department !== "" || winner.Institute !== "") &&
                                <p className="winning_dept">{winner.department}<br />{winner.Institute}</p>
                            }
                            {(winner.bio1 !== "") && <p dangerouslySetInnerHTML={{ __html: winner.bio1 }}></p>}
                            {(winner.bio2 !== "") && <p dangerouslySetInnerHTML={{ __html: winner.bio2 }}></p>}
                            {(winner.bio3 !== "") && <p dangerouslySetInnerHTML={{ __html: winner.bio3 }}></p>}
                            {(winner.bio4 !== "") && <p dangerouslySetInnerHTML={{ __html: winner.bio4 }}></p>}
                            {(winner.bio5 !== "") && <p dangerouslySetInnerHTML={{ __html: winner.bio5 }}></p>}
                        </div>
                        <div className="right-region ps-3">
                            <div className="winner-image">
                                <img src={require(`../../Assets/Images/${winner.image}`)} alt={winner.name}></img>
                            </div>
                            <div className="winner-quote pt-3">
                                {(winner.quote1 !== "") && <p dangerouslySetInnerHTML={{ __html: winner.quote }}></p>}
                                <h5>{winner.image_name}</h5>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </div>
    )
}