import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import symposium from '../../Assets/Images/Mask Group 10.png';
// import franklin from '../../Assets/Images/frank_glorius.png';
import melanie_sanford from '../../Assets/Images/melanie_sanford.jpg';
import group216 from '../../Assets/Images/Group 216.png';
import bossbannser from '../../Assets/Images/BOSSXVIII-Banners-1125x100px-HD2-green.png';
import "./Home.css";

const Home = (props) => {
    return (
        <div className="Main-Content pb-5">
            <Container fluid className="custom-minwidth">
                <section className="home-info d-flex">
                    <div className="info-left">
                        <div className="aboutprize-block pt-4 pe-5">
                            <h4>About the prize</h4>
                            <p> The <span>Janssen Prize for Creativity in Organic Synthesis </span> was
                                <Link className="historyLink" to="/history"> established in 1986</Link>  by Janssen Pharmaceuticals,
                                members of the academic chemistry community and the
                                <a className="historyLink" href="https://www.boss-symposium.org/" > Belgian Organic Synthesis Symposium (BOSS)</a> to
                                bring attention to the positive impact of organic synthesis in drug discovery.
                            </p>
                            <div className="Container">
                                <ul >
                                    <li>
                                        This prestigious recognition is awarded every two years to a chemist, under the age of 50, who has made a significant contribution to the field of organic synthesis
                                    </li>
                                    <li>
                                        Nominations are submitted by the global academic community of organic chemists
                                    </li>
                                    <li>
                                        A panel of
                                        <Link className="historyLink" to="/thejury"> international jury members</Link>
                                        , chaired by Prof. B. Feringa, 2016 Nobel Prize winner, select the winner.
                                    </li>
                                    <li>
                                        The recipient receives 20.000 Euros, a trophy, a plaque and delivers a keynote address at BOSS.
                                    </li>
                                </ul>
                            </div>
                            <div className="Container d-flex align-items-center">
                                    <img alt="group216" className="group216" src={group216}></img>
                                <div className="ps-4">
                                    <p className="">
                                        Learn more about our 30-year legacy
                                        <Link className="historyLink" to="/history"> established in 1986 </Link>
                                        and our impressive winners
                                        <Link className="historyLink" to="/winners"> here</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="info-right">
                        <div className="winner-block pt-4">
                            <h4>Congratulations to our 2024 winner!</h4>
                            <div className="winner-sub d-flex">
                                <div className="winner-sub-image me-3">
                                    <img alt="melanie_sanford" className="melanie_sanford mb-3" src={melanie_sanford}></img>
                                    <p><span>Melanie S. Sanford</span><br/> Professor of Chemistry <br/> University of Michigan <br/> Ann Arbor, MI</p>
                                </div>
                                <div className="winner-sub-content">
                                    <p>Prof. Melanie S. Sanford is a highly creative scientist and a world leader in the fields of organic synthesis and catalysis. She has made outstanding contributions in developing novel methods for C-H functionalization and fluorination, in mechanistic studies of high valent organometallic complexes, and in designing organic electrolytes for redox flow batteries as modern energy storage systems.</p>
                                    <p>She will be recognized and deliver a keynote lecture at the <a className="historyLink" href="https://www.boss-symposium.org/" > BOSS XVIII </a> in Liège, Belgium, June 30 - July 5, 2024.</p>
                                    <p>She is the first female winner in the Prize’s 30+ year history. Find out more about her significant contributions to the field <Link className="historyLink" to="/winners"> here</Link>.</p>
                                </div>
                            </div>
                            {/* <p>
                                Frank Glorius, Professor of Chemistry,
                                Westfälische Wilhelms-Universität, was recognized at the July 2022 Symposoium.
                                Find out more about his accomplishments in catalytic hydrogenations, C-H activation,
                                visible light photocatalysis and organocatalysis with N-heterocyclic carbenes (NHCs)
                                <Link className="historyLink" to="/about"> here</Link>
                            </p>
                            <div className="winner-images d-flex pb-3">
                                <div className="frank_glorius">
                                    <img alt="franklin" className="franklin_img" src={franklin}></img>
                                </div>
                                <div className="symposium ms-2">
                                    <img alt="symposium" className="symposium_img" src={symposium}></img>
                                </div>
                            </div>
                            <p>
                                Paul Janssen was a true pioneer, and it is a great honor for me to receive this
                                award and join the prestigious and impressive list of previous winners!
                            </p>
                            <p>
                                Chemical
                                research and organic synthesis are not only essential for the prosperity of our
                                modern society, but also offer exciting opportunities for creativity.
                            </p> */}
                        </div>
                        <div className="Boss_Banner pt-2 pb-2">
                            <img alt="bossbannser" className="bossbannser" src={bossbannser}></img>
                        </div>
                        {/* <div className="nomination-block">
                            <h4>Call for Nominations!</h4>
                            <p>We are pleased to announce the <span>“Call for Nominations”</span>
                                for the 2024 Janssen Prize for Creativity in Organic Synthesis. Submission
                                deadline is December 31, 2023. Further details on the nomination process can be
                                found <Link className="historyLink" to="/nominations"> here</Link>.
                                <br /> <br /> The 2024 Janssen Prize will be announced at the
                                <a target="_blank" className="historyLink" rel="noreferrer" href="https://www.boss-symposium.org/"> BOSS XVIII Symposium </a>
                                (June 30 – July 5, 2024)
                            </p>
                        </div> */}
                    </div>
                </section>
            </Container>
        </div>
    );
}

export default Home;