import React from "react";
import { Container } from "react-bootstrap";
import franklin_glorius from '../../Assets/Images/Group 5.png';
import "./About.css";

const About = (props) => {
    return (
        <div className="About-Content">
            <Container fluid className="custom-minwidth">
                <section>
                    <div className="About-Main pt-4">
                        <section className="d-flex pt-3">
                            <div className="custom-about-block w-75 pe-5">
                                <h4 className="">Professor Frank Glorius, Winner Of
                                    The 2022 Janssen Prize For Creativity In Organic Synthesis.</h4>
                                <p className="about-prof">Professor Of Chemistry <br />
                                    Westfälische Wilhelms-Universität Münster</p>
                                <p> Professor Glorius is an international leader and highly innovative scientist in the
                                    fields of organic synthesis and catalysis with outstanding contributions and accomplishments,
                                    in particular, in catalytic hydrogenations, C-H activation, visible
                                    light photocatalysis and organocatalysis with N-heterocyclic carbenes (NHCs).</p>

                                <p>His research program on the hydrogenation of arenes has led to
                                    numerous major breakthroughs. This work is not only of high academic
                                    relevance, but also holds great potential for applications
                                    in industry. Asymmetric hydrogenation is one of the most reliable,
                                    widely used methods in the synthesis of chiral molecules on a
                                    laboratory as well as on an industrial scale. Despite great
                                    progress during the last five decades, the asymmetric and highly
                                    chemoselective hydrogenation of arenes and heteroarenes has
                                    remained a great challenge. Frank Glorius set out to tackle
                                    this challenge and came up with highly innovative solutions.</p>
                                <p>He achieved an initial breakthrough in 2004 with the stereoselective
                                    hydrogenation of auxiliary-substituted pyridines, which caught wide
                                    attention as the structures of the resulting enantiomerically pure
                                    piperidine products are important motifs in many biologically
                                    active molecules. An additional significant advance of his was
                                    the development of a switchable and enantioselective hydrogenation
                                    of substituted quinoxalines with Ru-NHC catalysts. Depending on
                                    the choice of NHC ligand, either one of the two aromatic rings of
                                    the quinoxaline system could be selectively reduced. In addition,
                                    his outstanding work on C-H activation, NHCs on surfaces,
                                    photocatalysis, smart screening methods (data generation) and
                                    machine learning has resulted in numerous high impact publications
                                    which contributed to shaping these novel fields of research.</p>
                                <p><span className="biography">BIOGRAPHY:</span><br />
                                    Frank Glorius was born in 1972 in Germany.
                                    From 1992-1997 he studied chemistry at the University of Hannover,
                                    Germany. In 1995/1996 he performed some research studies in the
                                    group of Prof. Paul A. Wender at Stanford University, USA. His
                                    diploma thesis (1997) was completed through a collaboration in
                                    the groups of Prof. Andreas Pfaltz (Max-Planck-Institut (MPI) für
                                    Kohlenforschung, Mülheim/Ruhr) and Prof. H. Martin R. Hoffmann
                                    (University of Hannover). Between 1997-2000, he worked towards
                                    his Ph.D. in the group of Prof. Andreas Pfaltz (University of
                                    Basel & MPI für Kohlenforschung) which he received with summa
                                    cum laude. In 2000/2001 moved to Harvard University where he
                                    joined the group of Prof. David A. Evans as a postdoctoral
                                    fellow. In 2001 he started his independent research career at
                                    the MPI für Kohlenforschung in collaboration with Prof. Alois
                                    Fürstner. In 2004 Frank Glorius became a C3-Professor for
                                    Organic Chemistry at the University of Marburg. Since 2007 he
                                    has been Full Professor of Organic Chemistry at the
                                    Westfälische Wilhelms-Universität Münster.</p>
                                <p>Frank Glorius has published close to 400 scientific articles,
                                    is an inventor on 13 patents and has been the recipient of
                                    several national and international awards and recognitions such
                                    as Elected Member of the German National Academy of Sciences,
                                    Leopoldina (2021), Mitsui Catalysis Award (2020),
                                    Otto-Roelen-Medal (DECHEMA & German Catalysis Society, 2020),
                                    Gay-Lussac Humboldt Award (France, 2019), Merck, Sharp & Dohme
                                    Award of the Royal Society of Chemistry (RSC, 2018), Arthur C.
                                    Cope Scholar Award of the American Chemical Society (ACS, 2018),
                                    Mukaiyama Award of The Society of Synthetic Organic Chemistry,
                                    Japan (SSOCJ, 2017), Gottfried Wilhelm Leibniz-Award (2013).</p>
                                <p>He has delivered hundreds of lectures around the world and is a
                                    scientific advisory board member for Symrise AG and of Leibniz-Institut
                                    für Katalyse e.V. (LIKAT). Furthermore, he serves as series editor of Topics
                                    in Organometallic Chemistry (Springer) and as a member of the advisory boards
                                    of Chemical Science, ChemPhotoChem, Helvetica Chimica, Acta, Organic Chemistry
                                    Frontiers, Advanced Synthesis & Catalysis.</p>
                                <p>Beyond his outstanding scientific contributions Frank Glorius
                                    is also an excellent academic teacher and mentor for 52
                                    Diploma and MSc theses, for 70 doctoral students (50 completed
                                    dissertations), 40 postdocs and for 2 Habilitations. 38 former coworkers
                                    have become Professors and all others started an industrial career.</p>
                            </div>
                            <div className="author-block w-25 ps-4">
                                <div className="px-4 align-items-center author-block-img">
                                    <img alt="Franklin Glorius" className="franklin_glorius" src={franklin_glorius}></img>
                                </div>
                                <div className="author-quote pt-4">
                                    <p>“Paul Janssen was a true pioneer, and it is a great honor for me to
                                        receive this award and join the prestigious and impressive
                                        list of previous winners!</p>
                                    <p>Chemical research and organic synthesis are not only essential
                                        for the prosperity of our modern society, but also offer exciting
                                        opportunities for creativity. We are all molecular artists!” </p>
                                    <h5>Frank Glorius</h5>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </Container>
        </div>
    )
};

export default About;