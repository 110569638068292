import React from "react";
import Header from './Components/Header'
import Footer from './Components/Footer'
import './App.css';
import Routes  from "./Routes.js"

export const PageLayout = (props) => {

    return (
      <div className="PageContent">
      <Header/>
      <div className="BodyContent">
        <Routes/>
      </div>
      <Footer/>
      </div>
    );
};