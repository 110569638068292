import React from "react";
import { Routes as Routing, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import History from "./Components/History/History";
import Winners from "./Components/Winners/Winners";
import Nominations from "./Components/Nominations/Nominations";
import Media from "./Components/Media/Media";
import Contact from "./Components/Contact/Contact";
import TheJury from "./Components/TheJury/TheJury";
import About from "./Components/About/About";

const Routes = (props) => {
    return (
        <>
            <Routing>
                <Route
                    exact
                    path="/"
                    element={<Home />}
                ></Route>
                <Route
                    exact
                    path="/history"
                    element={<History />}
                ></Route>
                <Route
                    exact
                    path="/winners"
                    element={<Winners />}
                ></Route>
                <Route
                    exact
                    path="/thejury"
                    element={<TheJury />}
                ></Route>
                <Route
                    exact
                    path="/nominations"
                    element={<Nominations />}
                ></Route>
                <Route
                    exact
                    path="/media"
                    element={<Media />}
                ></Route>
                <Route
                    exact
                    path="/contact"
                    element={<Contact />}
                ></Route>
                <Route
                    exact
                    path="/about"
                    element={<About />}
                ></Route>
            </Routing>
        </>);
}

export default Routes;
