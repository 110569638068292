import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Hilmar_Weinmann from '../../Assets/Images/Hilmar_Weinmann.jpg';
import "./Contact.css";

const Contact = (props) => {
    return (
        <div className="Contact-Content">
            <Container fluid className="custom-minwidth">
                <section>
                    <div className="Contact-Main pt-4">
                        <h4 className="title-text">Contact</h4>
                        <div className="custom-contact d-flex">
                            <div className="left-region">
                                <img alt="Hilmar_Weinmann" className="Hilmar_Weinmann" src={Hilmar_Weinmann}></img>
                            </div>
                            <div className="right-region px-3">
                                <p><span className="p_header">Dr. Hilmar Weinmann </span><br/> Director Discovery Process Research, Regional
                                     Head EMEA <br/> Chemical Process R&D <br/> Discovery, Product Development &
                                      Supply </p>
                                <p> Janssen Pharmaceutica NV, a Johnson & Johnson company <br/>
                                       Turnhoutseweg 30 <br/> 2340 Beerse <br/> Belgium 
                                       eMail address: <Link to="mailto:HWeinman@its.jnj.com" className="historyLink"
                                    target="_blank"> HWeinman@its.jnj.com </Link></p>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </div>
    )
};

export default Contact;