import React, { useState } from "react";
import { Container } from "react-bootstrap";
import TheJuryData from "./TheJury.json";
import "./TheJury.css";

export default function Winners() {
    const [jury, setJury] = useState(TheJuryData[0]);
    const [currentKey, setCurrentKey] = useState(0);
    const handleClick = (key, value) => {
        const selectedJury = TheJuryData[value];
        setCurrentKey(value);
        setJury(selectedJury);
    }


    const listItems = TheJuryData.map(jury =>
        <li key={jury.id} className="mb-3" onClick={() => handleClick("id", jury.id)} >
            <div className="jury-nav-list d-flex align-items-center">
            <div className="nav-left">
                <p className="custom-jury-nav">
                    {jury.name}<br />
                    {(jury.president !== "") && <span className="custom-jury-span">  {jury.president} <br /></span>}
                    <span className="custom-jury-about" dangerouslySetInnerHTML={{ __html: jury.about }}></span>
                </p>
            </div>
            <div className="nav-right">
                {(currentKey === jury.id) && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>}
            </div>
            </div>
        </li>
    );

    return (
        <div className="TheJury-Content">
            <Container fluid className="custom-minwidth">
                <section className="d-flex">
                    <div className="custom-nav pt-3">
                        <h4>The jury</h4>
                        <ul className="mt-4">{listItems}</ul>
                    </div>
                    <div className="customjury-block pt-4 ps-4">
                        <div className="left-region ps-3 pe-3">
                            <div className="summary_block pb-3">
                                {<h4 className="title-text">{jury.fullname}</h4>}
                                <h5 className="pt-2">Summary</h5>
                                {(jury.bio1 !== "") && <p>{jury.bio1}</p>}
                                {(jury.bio2 !== "") && <p>{jury.bio2}</p>}
                                {(jury.bio3 !== "") && <p>{jury.bio3}</p>}
                                {(jury.bio4 !== "") && <p>{jury.bio4}</p>}
                                {(jury.bio5 !== "") && <p>{jury.bio5}</p>}
                            </div>
                            <div className="past-jury py-4">
                                <h4>Past jury members and presidents of the janssen prize jury</h4>
                                <div className="past-jury-list">
                                    <div className="row">
                                        <div className="col">
                                            <ul>
                                                <li>Sir D. Barton </li>
                                                <li>H. Winberg </li>
                                                <li>M. Julia </li>
                                                <li>U. Schöllkopf </li>
                                                <li>L. Ghosez </li>
                                                <li>J. Martin </li>
                                                <li>J. Normant </li>
                                                <li>D. Seebach </li>
                                                <li>A. Krief </li>
                                            </ul>
                                        </div>
                                        <div className="col">
                                            <ul>
                                                <li>S. Ley</li>
                                                <li>W. Reetz</li>
                                                <li>P De Clercq</li>
                                                <li>JM Lehn</li>
                                                <li>K.C. Nicolaou</li>
                                                <li>S. Kobayashi</li>
                                                <li>J. Barlengua</li>
                                                <li>F. Diederich</li>
                                                <li>P. Kociensky</li>
                                            </ul>
                                        </div>
                                        <div className="col-5">
                                            <ul>
                                                <li>I. Marko</li>
                                                <li>S. Zard</li>
                                                <li>P. Knochel</li>
                                                <li>B. Abegaz</li>
                                                <li>B. Davis</li>
                                                <li>N. De Kimpe</li>
                                                <li>M. Janssen (secretary)</li>
                                                <li>G. Van Lommen (secetary).</li>
                                                <li>L. Meerpoel (secretary)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-region ps-3">
                            <div className="jury-image">
                                <img src={require(`../../Assets/Images/${jury.image}`)} alt={jury.name}></img>
                            </div>
                            <div className="jury-quote pt-3">
                                <h5><b>{jury.fullname}</b></h5>
                                <p>
                                    {(jury.image_about1 !== "") && jury.image_about1}
                                    {(jury.image_about2 !== "") && <span><br />{jury.image_about2}</span>}
                                    {(jury.image_about3 !== "") && <span><br />{jury.image_about3}</span>}
                                    {(jury.image_about4 !== "") && <span><br />{jury.image_about4}</span>}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </div>
    )
}