// import logo from './logo.svg';
import "./Assets/Font/font.css";
import './App.css';
import { PageLayout } from './PageLayout';


function App() {
  return (
    <PageLayout></PageLayout>
  );
}

export default App;
