import React from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./Header.css";


const Header = () => {
  return (
      <div className="App">
        <div className="App-header">
          <Container fluid className="pt-3 custom-minwidth">
            <div className='App-header-name'>
              <h2 className=''>Janssen Prize for <br/> Creativity in Organic Synthesis</h2>
            </div>
            <nav className="navbar navbar-expand-lg navbar-light">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item me-2">
                    <NavLink className="nav-link" to="/">Home</NavLink>
                  </li>
                  <li className="nav-item me-2">
                    <NavLink className="nav-link" to="/history">History</NavLink>
                  </li>
                  <li className="nav-item me-2">
                    <NavLink className="nav-link" to="/winners">Winners</NavLink>
                  </li>
                  <li className="nav-item me-2">
                    <NavLink className="nav-link" to="/thejury">The jury</NavLink>
                  </li>
                  <li className="nav-item me-2">
                    <NavLink className="nav-link" to="/nominations">Nominations</NavLink>
                  </li>
                  <li className="nav-item me-2">
                    <NavLink className="nav-link" to="/media">Media</NavLink>
                  </li>
                  <li className="nav-item me-2">
                    <NavLink className="nav-link" to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </div>
              {/*<div className="collapse navbar-collapse" id="navbarSocial">
                <ul className="navbar-nav">
                  <li className="nav-item me-4">
                  <a href="https://twitter.com/intent/tweet?text=Congratulations%20to%20Prof.%20Phil%20Baran%2C%20winner%20of%20the%202020%20Janssen%20Prize%20for%20Creativity%20in%20Organic%20Synthesis%20for%20his%20transformative%20contributions%20in%20the%20field%20of%20atom%20economic%20catalysis%20and%20natural%20product%20synthesis.%20http%3A%2F%2Fbit.ly%2F3cNevlq%20%23BOSS_Symposium%0Apic.twitter.com%2F40cGDUgZHE%0A" target="_blank" rel="noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                  </a>
                  </li>
                  <li className="nav-item me-4">
                  <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=http%3A%2F%2Fwww.janssenprizeorganicsynthesis.com%2F" target="_blank" rel="noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                  </a>
                  </li>
                </ul>
  </div>*/}
            </nav>

          </Container>
        </div>
      </div>
  );
}

export default Header;